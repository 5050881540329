/* styles.css */
.highlight-green {
  position: relative;
  display: inline-block;
  color: rgb(68, 143, 96);
  /* Remove the background gradient */
  background: none;
}

/* Ensure background-color for .highlight */
.highlight-green::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.15em;
  background-color: rgb(68, 143, 96);
  border-radius: 20px;
  transform: skewX(-12deg);
}
/* Remove fading effect */
.highlight {
    position: relative;
    display: inline-block;
    color: rgb(103, 177, 224);
    /* Remove the background gradient */
    background: none;
  }
  
  /* Ensure background-color for .highlight */
  .highlight::before {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.15em;
    background-color: rgba(144, 205, 244, 1); /* Blue color */
    border-radius: 20px;
    transform: skewX(-12deg);
  }
  
  /* Remove fading effect */
.highlight-t {
  position: relative;
  display: inline-block;
  color: rgb(224, 163, 103);
  /* Remove the background gradient */
  background: none;
}

/* Ensure background-color for .highlight */
.highlight-t::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.15em;
  background-color: rgb(224, 163, 103); /* Blue color */
  border-radius: 20px;
  transform: skewX(-12deg);
}

  /* Grid background styles */
  .grid-background {
    position: relative;
    background-size: 150px 150px;
    background-image: 
      linear-gradient(to right, rgb(210, 210, 210) 0px, transparent 0.5px),
      linear-gradient(to bottom, rgb(210, 210, 210) 0px, transparent 0.5px);
    overflow: hidden;
  }
  
  .grid-background::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* Adjust the gradient stops as per your design needs */
    background-image: 
    linear-gradient(to right, rgb(255, 255, 255), rgba(255, 255, 255, 0) 25%, rgba(255, 255, 255, 0) 80%, rgb(255, 255, 255));
    pointer-events: none; /* Ensure the gradient doesn't interfere with interactions */
  }
  
  /* White background for elements with .no-background class */
  .no-background {
    background-color: white !important;
  }
  
  /* CalendarComponent.css */
.rbc-toolbar {
    background-color: #f8f9fa;
    padding: 10px;
    color: black;
  }
  
  .rbc-event {
    font-size: 14px;
    padding: 5px;
  }
  
  .rbc-event-content p {
    margin: 0;
  }
  
  .rbc-day-bg.rbc-today {
    background-color: #eaeaea;
  }
  
  .rbc-date-cell {
    color: rgb(152, 152, 152); /* Set the color of the date text to black */
  }

/* TeamCarousel.css */
.slick-slide {
    display: flex;
    justify-content: center;
  }
  
  .slick-dots {
    bottom: -30px; /* Adjust the position of dots */
  }
  
  .slick-prev:before, .slick-next:before {
    color: black; /* Change arrow color */
  }
  