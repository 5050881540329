.carousel-container {
    height: 100vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
  }
  
  .carousel {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .carousel-item {
    width: 80%;
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ccc;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    opacity: 0;
    transform: translateY(50px);
    transition: opacity 0.3s, transform 0.3s;
  }
  
  .carousel-item.active {
    opacity: 1;
    transform: translateY(0);
  }
  